<template>
<section class="trusted-brands-section">
	<div class="flex justify-center">
		<div class="flex text-poodle-blue-800 w-full max-w-640 justify-center py-20">
			<div class="flex-col xl:max-w-6xl md:max-w-lg max-w-xs w-full margin-auto justify-center">
				<div class="py-4 text-center">
					<h1 class="xl:text-5xl text-4xl font-bold text-poodle-blue-800">Brands that Trust Us</h1>
				</div>
				<div class="max-md:hidden grid xl:grid-cols-3 md:grid-cols-2 gap-6 px-6 py-20">	
					<div class="grid relative brand justify-self-center xl:h-42 h-32 xl:w-74 w-56" v-for="brand in brands" :key="brand.id">
						<a :href="brand.link" class="absolute bottom-0 left-0 xl:h-32 h-24 xl:w-64 w-48">
							<img :class="['xl:h-32 h-24 xl:w-64 w-48 rounded-lg',brand.style]" :src="brand.icon" :alt="brand.name" />
						</a>
						<img src="/img/icons/partner/partner_green.svg" class="absolute top-0 right-0 overflow-visible stamp xl:size-20 md:size-16" v-if="brand.partner" />
					</div>
				</div>
				<div ref="swipeArea" class="md:hidden overflow-hidden h-112 screen py-20">
					<div class="flex gap-4 pl-16" :style="{transform: 'translateX(' + (-75 * currentSlide) + '%)'}">
            <div class="flex-none relative h-32 w-56" v-for="(brand) in brands" :key="brand.id">
              <a :href="brand.link" class="absolute bottom-0 left-0">
								<img :class="['h-24 w-48 rounded-lg',brand.style]" :src="brand.icon" :alt="brand.name" />
              </a>
							<img src="/img/icons/partner/partner_green.svg" class="absolute top-0 right-0 overflow-visible stamp size-16" v-if="brand.partner" />
            </div>
          </div>
				</div>
			</div>
		</div>
	</div>
</section>
</template>

<script>
	import Hammer from 'hammerjs';
	
export default {
		name: 'TrustedBrandsComponent',
		data() {
				return {
						currentSlide: 0,
						brands: [
								{ id: 1, name: 'Empvision', description: '...', icon: '/img/icons/brands/Empvision.png', style: "bg-white border border-zinc-10", link: 'https://www.empvision.com', partner: true },
								{ id: 2, name: 'Empowerbrand', description: '...', icon: '/img/icons/brands/Empowerbrand.png', style: "bg-white border border-zinc-10", link: '', partner: true},
								{ id: 3, name: 'CM Maia', description: '...', icon: '/img/icons/brands/Maia.png', style: "", link: 'https://www.cm-maia.pt', partner: false},
								{ id: 4, name: 'ANJE', description: '...', icon: '/img/icons/brands/ANJE.png', style: "", link: 'https://anje.pt/', partner: false },
								{ id: 5, name: 'CM Matosinhos', description: '...', icon: '/img/icons/brands/Matosinhos.png', style: "", link: 'https://www.cm-matosinhos.pt', partner: false },
								{ id: 6, name: '351 Startup Association', description: '...', icon: '/img/icons/brands/351.png', style:"bg-[#609CF8]", link: 'https://351startups.com/', partner: false},
						]
				};
		},
		mounted() {
				this.createSwipeHandler();
		},
		methods: {
				createSwipeHandler() {
						const swipeArea = this.$refs.swipeArea;
						const mc = new Hammer(swipeArea);
						
						mc.on("swipeleft", () => {
								if (this.currentSlide < this.brands.length - 1) {
										this.currentSlide++;
								}
						});
						
						mc.on("swiperight", () => {
								if (this.currentSlide > 0) {
										this.currentSlide--;
								}
						});
				}
		}
}

</script>

<style scoped>
</style>
