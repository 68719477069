<template>
  <div id="app" class="flex flex-col h-screen">
    <HeaderComponent />
    <router-view />
    <FooterComponent />
  </div>
</template>

<script>
import HeaderComponent from './components/HeaderComponent.vue';
import FooterComponent from './components/FooterComponent.vue';

export default {
  name: 'App',
  components: {
    HeaderComponent,						
    FooterComponent
  }
};
</script>

<style>
body {
		margin: 0;
		@apply font-sans
}
</style>
