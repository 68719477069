<template>
	<section class="flex max-xl:flex-col about-section justify-center overflow-hidden">
		<div class="flex relative p10 xl:w-1/2 w-full xl:max-w-320 h-112 bg-poodle-blue-600 xl:justify-end justify-center">
			<div class="flex h-full xl:max-w-xl md:max-w-lg max-w-xs w-full bg-poodle-blue-600 xl:-skew-x-12 xl:origin-top-right text-white xl:justify-start justify-start">
				<div class="flex flex-col xl:max-w-xl md:max-w-lg w-full xl:skew-x-12 xl:justify-start  md:py-20 py-10 space-y-8 xl:origin-top-right">
					<h1 class="font-semibold xl:text-5xl text-4xl">Where We Act</h1>
					<div class="flex-col space-y-6">
						<div class="flex stage " v-for="stage in stages" :key="stage.id">
							<img class="size-8" :src="stage.icon" :alt="stage.name" />
							<p class="px-4 xl:h-8 h-auto xl:text-xl text-lg font-medium leading-relaxed">{{ stage.description }}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="flex relative p10 xl:w-1/2 w-full xl:max-w-320 h-112 bg-poodle-white xl:justify-start justify-center">
			<div class="flex h-full xl:max-w-xl max-w-lg max-w-xs w-full bg-poodle-white xl:-skew-x-12 xl:origin-top-left text-poodle-blue-600 xl:justify-end justify-center">
				<div class="flex flex-col xl:max-w-xl md:max-w-lg max-w-xs xl:skew-x-12 xl:w-4/5 w-full md:py-20 py-10 space-y-8 xl:origin-top-right">
					<h1 class="font-semibold xl:text-5xl text-4xl">How We Act</h1>
					<div class="flex-col space-y-6">
						<div class="flex method items-center" v-for="method in methodologies" :key="method.id">
							<img class="size-8" :src="method.icon" :alt="method.name" />
							<p class="px-4 h-8 xl:text-xl text-lg font-medium leading-relaxed">{{ method.description }}</p>
						</div>
					</div>
				</div>
			</div>
		</div>

	</section>
</template>



<script>
export default {
		name: 'AboutSectionComponent',
		data() {
				return {
						stages: [
								{ id: 1, name: 'Ideation', description: 'Ideation and Business Development Stage', icon: '/img/icons/about/Ideation.png' },
								{ id: 2, name: 'Scale Up', description: 'During Scale Up', icon: '/img/icons/about/Scale-Up.png'},
								{ id: 3, name: 'Day to Day', description: 'In the day-to day operations of companies', icon: '/img/icons/about/DtD.png'},
								{ id: 4, name: 'Mergers and Acquisitions', description: 'Mergers and acquisitions of companies', icon: '/img/icons/about/MA.png'},
						],
						methodologies: [
								{ id: 1, name: 'Online', description: 'Online check-up', icon: '/img/icons/methodologies/Online.png' },
								{ id: 2, name: 'Plan and Budget', description: 'Plan and Budget', icon: '/img/icons/methodologies/P&B.png'},
								{ id: 3, name: 'Implementing', description: 'Implementing', icon: '/img/icons/methodologies/Implementing.png'},
								{ id: 4, name: 'Monitoring and Control', description: 'Monitoring and control', icon: '/img/icons/methodologies/M&C.png'},

						],
		};
	}
}
</script>

<style scoped>
.about-section {
  /* Your styles here */
}
</style>
