<template>
<header class="flex backdrop-blur-2xl fixed top-0 left-0 w-full z-50 xl:px-40 py-4 justify-center bg-poodle-blue-800 items-center h-20">
	<div class="flex max-w-6xl w-full bg-opacity-50 items-center space-x-72 justify-center">
		<router-link to='/'>
			<img src="/img/logos/Header.svg" alt="Poodle IT Logo" class="shrink-0 w-28 h-24" />
		</router-link>
		<nav class="hidden flex items-center space-x-10 text-base font-semibold text-white">
			<ul class="flex items-start space-x-10">
				<li><a href="#about">About Us</a></li>
				<li><a href="#services">Services</a></li>
				<li><a href="#academy">Academy</a></li>
				<li><a href="#help">Help</a></li>
			</ul>
			<button class="flex px-2.5 py-2 justify-center items-center space-10 border-solid border-2 border-white rounded-3xl ext-white bg-opacity-10">Schedule a Checkup</button>
		</nav>
	</div>
</header>
</template>

<script>
</script>

<style scoped>
</style>
