<template>
<section class="services-section">
	<div class="flex justify-center">
		<div class="flex text-poodle-blue-800 w-full xl:h-160 max-w-640 justify-center py-20">
			<div class="flex-col xl:max-w-6xl md:max-w-lg w-full margin-auto justify-center">
				<div class="flex justify-center py-4">
					<h1 class="xl:text-5xl text-4xl font-bold text-poodle-blue-800">Services</h1>
				</div>
				<div class="flex xl:flex-row flex-col justify-between max-xl:space-y-8 items-center xl:py-20 py-10 w-full">
					<div class="flex flex-col service items-center xl:w-1/4 max-xl:max-w-xs px-8" v-for="service in services" :key="service.id">
						<img class="h-16 w-16" :src="service.icon" :alt="service.name" />
						<h3 class="text-xl font-extrabold text-poodle-blue-800">{{ service.name }}</h3>
						<p class="text-center">{{ service.description }}</p>
						<!--a class="text-lg font-bold text-poodle-green" :href="service.link">Learn More</a-->
					</div>
				</div>
			</div>
		</div>
	</div>

</section>
</template>

<script>
export default {
	name: 'ServicesSectionComponent',
	data() {
		return {
			services: [
				{ id: 1, name: 'Cybersecurity', description: "Protect your digital fortress with Poodle IT's cutting-edge cybersecurity solutions.", icon: '/img/icons/services/Cybersecurity.png', link: '#cybersecurity' },
				{ id: 2, name: 'IT Development', description: "Transform your ideas into robust digital solutions with Poodle IT's IT development expertise.", icon: '/img/icons/services/IT.png', link: '#it' },
				{ id: 3, name: 'Blockchain', description: "Empower your business with secure and decentralized transactions.", icon: "/img/icons/services/Blockchain.png", link: '#blockchain' },
				{ id: 4, name: 'Artificial Intelligence', description: "Unleash the power of Artificial Intelligence with Poodle IT.", icon: '/img/icons/services/AI.png', link: '#ai' },
			]
		};
	}
}
</script>

<style scoped>
</style>
