<template>
  <div class="home grow">
    <HeroSectionComponent />
    <ServicesSectionComponent />
    <AboutSectionComponent />
    <TrustedBrandsComponent />
  </div>
</template>

<script>
// Import the components that make up the home page
import HeroSectionComponent from '@/components/HeroSectionComponent.vue';
import ServicesSectionComponent from '@/components/ServicesSectionComponent.vue';
import AboutSectionComponent from '@/components/AboutSectionComponent.vue';
import TrustedBrandsComponent from '@/components/TrustedBrandsComponent.vue';

export default {
  name: 'HomeComponent',
  components: {
    HeroSectionComponent,
    ServicesSectionComponent,
    AboutSectionComponent,
    TrustedBrandsComponent
  }
};
</script>

<style scoped>
.home {
}
</style>
