import { createRouter, createWebHistory } from 'vue-router';
import HomeComponent from '@/components/HomeComponent.vue'; // Adjust the path as needed
import NotFoundComponent from '@/components/NotFoundComponent.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeComponent,
    meta: { title: 'Poodle IT'}
  },
	{
	path:	'/:pathMatch(.*)*',
	name: 'Not Found',
	component: NotFoundComponent
	}
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const defaultTitle = 'Poodle IT';
  document.title = to.meta.title || defaultTitle;
  next();
});

export default router;