<template>
  <div class="py-40">
    <h1 class="text-center text-6xl text-poodle-blue text-bold">404</h1>
		<h2 class="text-center text-4xl text-poodle-blue text-bold">Page Not Found</h2>
    <p class="py-20 text-center">Sorry, the page you are looking for does not exist.</p>
  </div>
</template>

<script>
export default {
  name: 'NotFoundComponent'
}
</script>
