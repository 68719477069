<template>
  <footer class="footer h-full">
		<div class="flex w-full justify-center bg-poodle-white h-full">
			<div class="flex flex-col xl:max-w-6xl md:max-w-lg justify-start items-center py-16 space-y-24">
				<div class="flex items-center">
					<img src="/img/logos/Footer.svg" alt="Poodle IT Logo" class="h-14 w-64" />
				</div>
				<div class="flex hidden flex-row justify-center h-28">
					<div class="hidden flex flex-row space-x-20">
						<div class="hidden flex flex-col w-32 space-y-3 text-gray-500">
							<h3 class="text-xl semibold leading-7 pb-3 text-black">Company</h3>
							<a>About Us</a>
							<a>Our Services</a>
						</div>
						<div class="hidden flex flex-col w-32 space-y-3 text-gray-500">
							<h3 class="text-xl semibold leading-7 pb-3 text-black">Academy</h3>
							<a>Our Courses</a>
						</div>
						<div class="hidden flex flex-col w-32 space-y-3 text-gray-500">
							<h3 class="text-xl semibold leading-7 pb-3 text-black">Help</h3>
							<a>Contact Us</a>
							<a>FAQs</a>
						</div>
					</div>
					<div class="flex flex-row justify-center space-x-4 items-center">
						<div class="flex social items-center justify-center items-center" v-for="social in socials" :key="social.id">
							<a :href="social.link" class="w-full h-full justify-center items-center">
								<div :class="['flex justify-center items-center rounded-full p-2 shadow-md h-8 w-8', social.box]">
									<i :class="social.icon" :alt="social.name" />
								</div>
							</a>
						</div>
					</div>
				</div>
				<div>
					<p class="text-gray-500 text-sm font-normal leading-5">©2024 Poodle IT</p>
				</div>
			</div>
		</div>
	</footer>	
</template>

<script>
export default {
		name: 'FooterComponent',
		data() {
				return {
						socials: [
								{ id: 1, name: 'Facebook', icon: 'fa-brands fa-facebook-f justify-center', link: '#Facebook', box: "bg-white text-poodle-blue-800 hover:bg-poodle-blue-800 hover:text-white"},
								{ id: 2, name: 'Instagram', icon: 'fa-brands fa-instagram', link: '#Instagram', box: "bg-poodle-blue-800 text-white hover:bg-white hover:text-poodle-blue-800"},
								{ id: 3, name: 'Linkedin', icon: 'fa-brands fa-linkedin-in', link: '#Instagram', box:"bg-white text-poodle-blue-800 hover:bg-poodle-blue-800 hover:text-white"},
								{ id: 4, name: 'Email', icon: 'fa-solid fa-envelope', link: '#Mail', box:"bg-poodle-blue-800 text-white hover:bg-white hover:text-poodle-blue-800"},


						]
				}
		}
}
</script>

<style scoped>
.footer {
  /* Your styles here */
}
</style>
