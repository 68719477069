<template>
<section class="flex hero-section min-width-0 pt-20 overflow-hidden justify-center max-h-1/5">
	<div class="flex bg-[url('../public/img/backgrounds/hero.png')] overflow-hidden bg-cover bg-center text-white w-full xl:h-200 xl:max-w-640 h-screen justify-center shrink-0">
		<div class="flex max-xl:backdrop-blur-sm w-full justify-center">
			<div class="grid xl:py-20 xl:max-w-6xl xl:justify-start md:max-w-lg px-4 py-16 w-full items-center">
				<div class="xl:max-w-2xl md:max-w-lg max-w-xs xl:justify-self-start justify-self-center">
					<h1 class="xl:text-xl text-lg py-4"><span class="font-bold">TRUST</span><span class="font-semibold"> THE POODLE</span></h1>
					<p class="xl:text-6xl text-4xl font-bold tracking-tighter leading-tight">We exist to build a better and safer future for companies</p>
					<p class="text-base py-4 w-5/6 leading-7 align-bottom">A strong IT and Cybersecurity team provides the stability that a business needs to survive in a constantly changing world.</p>
				</div>
				<div class="xl:max-w-2xl max-md:justify-self-center">
					<a href="mailto:daniel@poodleit.pt">
						<button class="cta-button font-semibold rounded-[30px] bg-poodle-green w-44 h-14 mt-auto md:">Contact Us</button>
					</a>
				</div>
			</div>
		</div>
	</div>
</section>
</template>

<script>
</script>

<style scoped>
</style>
